/**
 * Rememba web-application
 * @copyright Anton Lovchikov, http://antiflash.ru, 2013-2017
 * @author Alexander Burtsev, http://burtsev.me, 2013-2017
 * All rights reserved
 */
define('settings', [], function () {
    'use strict';
    var DEBUG = location.search && location.search.match(/debug/i) || false, CSRF = window.CSRF || null, YA_METRIKA = window.YA_METRIKA || null;
    window.DEBUG = DEBUG;
    $.ajaxSetup({
        cache: false,
        dataType: 'json'
    });
    $.ajaxPrefilter(function (options, original) {
        if (options.type !== 'GET') {
            var data = original.data || {};
            $.extend(data, CSRF);
            options.data = $.param(data);
        }
    });
    function log(msg, resp) {
        if (!window.console || !console.log) {
            return;
        }
        var fields = [
                'nearest_time',
                'nearest_cards_count',
                'next_cards_count',
                'feeder_speed_cards_count'
            ], datetime = new Date().toString().match(/\d+\:\d+\:\d+/)[0];
        console.log('[' + datetime + ']: ' + msg.toUpperCase(), resp ? _.pick(resp, fields) : '');
    }
    return {
        CSRF: CSRF,
        ERRORS: window.errors || {},
        YA_METRIKA: YA_METRIKA,
        COLORS: {
            1: 'yellow',
            2: 'red',
            3: 'blue',
            4: 'green',
            5: 'violet',
            6: 'white'
        },
        HTTP_UNAUTHORIZED: 401,
        LEARNED_NETWORK_DELAY: 5 * 1000,
        DELTA_SCROLL_LOADING: 10,
        DEBUG: DEBUG,
        log: log
    };
});
define('stat', ['settings'], function (settings) {
    'use strict';
    var stat = {
        push: function (goal) {
            this._pushToYandex(goal);
            settings.log('Goal: ' + goal);
        },
        _pushToYandex: function (goal) {
            if (!settings.YA_METRIKA) {
                return;
            }
            if (document.readyState !== 'complete') {
                var args = arguments;
                return $(window).load(function () {
                    this._pushToYandex.apply(this, args);
                }.bind(this));
            }
            var counter = window[settings.YA_METRIKA];
            if (goal && counter && counter.reachGoal) {
                counter.reachGoal(goal);
            }
        }
    };
    return stat;
});
define('utils', ['settings'], function (settings) {
    'use strict';
    var utils = {
        convertErrors: function (resp) {
            var error = resp.error || {}, errorData = error.data || {}, errors = {};
            _.each(errorData, function (value, key) {
                if (settings.ERRORS[key] && settings.ERRORS[key][value]) {
                    errors[key] = settings.ERRORS[key][value];
                } else {
                    errors[key] = value;
                }
            });
            return errors;
        },
        debounce: function (fn, delay, context) {
            var timer = null;
            context = context || this;
            delay = delay || 100;
            return function () {
                var args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    fn.apply(context, args);
                }, delay);
            };
        },
        inflect: function (i, forms) {
            var index, dec = (i = parseInt(i, 10)) % 10, hun = i % 100;
            if (dec === 1 && hun !== 11) {
                index = 0;
            } else if (dec >= 2 && dec <= 4 && Math.floor(hun / 10) !== 1) {
                index = 1;
            } else {
                index = 2;
            }
            return forms[index];
        },
        kilofication: function (num) {
            if (!num) {
                return num;
            }
            return num >= 1000 ? Math.floor(num / 1000) + 'K' : num;
        },
        nl2br: function (str) {
            str = _.escape(str);
            return str.replace(/\n/g, '<br/>');
        },
        padNumber: function (num) {
            return num < 10 ? '0' + num : num;
        }
    };
    return utils;
});
define('user/user.model', [
    'settings',
    'utils'
], function (settings, utils) {
    'use strict';
    var User = Backbone.Model.extend({
        ROLE_LOOKER: 'looker',
        ROLE_MEMBER: 'member',
        url: '/api/user',
        urlRegister: '/api/user/register',
        urlUpdate: '/api/user/edit',
        urlLogin: '/api/user/login',
        urlForgot: '/api/user/forgot',
        validation: {
            email: [
                {
                    required: true,
                    msg: 'empty'
                },
                {
                    pattern: 'email',
                    msg: settings.ERRORS.email.EMAIL
                }
            ],
            password: {
                required: true,
                msg: 'empty'
            }
        },
        changeMail: function (data) {
            this.set(data);
            if (this.isValid(true)) {
                $.post(this.urlUpdate, data).done(function (resp) {
                    this.set(resp.user);
                    this.trigger('changemail:success');
                }.bind(this)).fail(function (xhr) {
                    var errors = utils.convertErrors(xhr.responseJSON);
                    this.trigger('changemail:fail', this, errors);
                }.bind(this));
            }
        },
        forgot: function (data) {
            var errors = this.preValidate(data);
            if (errors) {
                this.trigger('validated:invalid', this, errors);
            } else {
                this.trigger('validated:valid', this);
                $.post(this.urlForgot, data).done(function () {
                    this.trigger('forgot:success', this);
                }.bind(this)).fail(function (xhr) {
                    var errors = utils.convertErrors(xhr.responseJSON);
                    this.trigger('forgot:fail', this, errors);
                }.bind(this));
            }
        },
        resetPassword: function (data, url) {
            $.post(url, data).done(function () {
                this.trigger('resetpassword:success', this);
            }.bind(this)).fail(function (xhr) {
                var errors = utils.convertErrors(xhr.responseJSON);
                this.trigger('resetpassword:fail', this, errors);
            }.bind(this));
        },
        login: function (data) {
            this.set(data);
            if (this.isValid(true)) {
                $.post(this.urlLogin, data).done(function () {
                    this.trigger('login:success', this);
                }.bind(this)).fail(function (xhr) {
                    var errors = utils.convertErrors(xhr.responseJSON);
                    this.trigger('login:fail', this, errors);
                }.bind(this));
            }
        },
        reg: function (data) {
            this.set(data);
            if (this.isValid(true)) {
                $.post(this.urlRegister, data).done(function (resp) {
                    this.set(resp.user);
                    this.set({ password: data.password });
                    this.trigger('reg:success', this);
                }.bind(this)).fail(function (xhr) {
                    var errors = utils.convertErrors(xhr.responseJSON);
                    this.trigger('reg:fail', this, errors);
                }.bind(this));
            }
        },
        parse: function (resp) {
            resp = resp || {};
            resp.user = resp.user || {};
            if (resp.status) {
                return resp.user;
            }
        }
    });
    _.extend(User.prototype, Backbone.Validation.mixin);
    return User;
});
define('layouts/form.view', [], function () {
    'use strict';
    var FormView = Marionette.ItemView.extend({
        ui: {
            form: 'form',
            inputs: 'form :input'
        },
        events: {
            'click .js-close': 'close',
            'submit @ui.form': 'submit'
        },
        initialize: function () {
            this.bindUIElements();
            this.$el.on('disappear.tamia dismiss.modal.tamia', function () {
                if (this.ui.inputs) {
                    this.ui.inputs.removeState('invalid');
                }
            }.bind(this));
        },
        close: function (force) {
            if (this.ui.form) {
                if (!force && this.ui.form.hasState('loading')) {
                    return;
                }
                this.ui.form.trigger('loading-stop.tamia');
            }
            this.$el.trigger('disappear.tamia');
        },
        closeForce: function () {
            this.close(true);
        },
        keyupCheck: function () {
            if (this.ui.inputs) {
                this.ui.inputs.off('.keycheck').on('keyup.keycheck', function () {
                    this.validate();
                }.bind(this));
            }
        },
        toggle: function () {
            if (this.$el.hasState('hidden')) {
                this.open();
            } else {
                this.close();
            }
        },
        reset: function () {
            this.form.reset();
            $('input[type="hidden"]', this.form).val('');
        },
        validate: function () {
        }
    });
    return FormView;
});
define('user/user.login.form.view', ['layouts/form.view'], function (FormView) {
    'use strict';
    var UserLoginFormView = FormView.extend({
        el: $('.js-login-form'),
        initialize: function () {
            FormView.prototype.initialize.apply(this, arguments);
            this.listenTo(this.model, 'validated:valid', function () {
                if (this.$el.hasState('hidden')) {
                    return;
                }
                this.ui.form.trigger('loading-start.tamia');
                this.ui.inputs.removeState('invalid');
            }).listenTo(this.model, 'validated:invalid', this.errback).listenTo(this.model, 'login:fail', this.errback).listenTo(this.model, 'login:success', function () {
                location.reload();
            });
        },
        errback: function (model, errors) {
            if (this.$el.hasState('hidden')) {
                return;
            }
            this.ui.form.trigger('loading-stop.tamia');
            this.showErrors(errors, false);
            this.keyupCheck();
        },
        showErrors: function (errors, soft) {
            var self = this;
            this.ui.inputs.each(function () {
                var $this = $(this), error = errors[this.name];
                if (error && error !== 'empty' && !soft) {
                    self.trigger('error:promo', error);
                }
                $this.toggleState('invalid', !!error);
            });
            if (!soft) {
                this.ui.inputs.filter('.is-invalid').first().focus();
            }
        },
        submit: function () {
            var data = Backbone.Syphon.serialize(this);
            this.model.login(data);
            return false;
        },
        validate: function () {
            var data = Backbone.Syphon.serialize(this), errors = this.model.preValidate(data) || {};
            this.showErrors(errors, true);
        }
    });
    return UserLoginFormView;
});
define('user/user.forgot.form.view', [
    'layouts/form.view',
    'user/user.login.form.view'
], function (FormView, UserLoginFormView) {
    'use strict';
    var UserForgotFormView = UserLoginFormView.extend({
        el: $('.js-forgot-form'),
        initialize: function () {
            FormView.prototype.initialize.apply(this, arguments);
            this.message = this.$el.data('message');
            this.listenTo(this.model, 'validated:valid', function () {
                this.ui.form.trigger('loading-start.tamia');
                this.ui.inputs.removeState('invalid');
            }).listenTo(this.model, 'validated:invalid', this.errback).listenTo(this.model, 'forgot:fail', this.errback).listenTo(this.model, 'forgot:success', function () {
                this.$el.trigger('close.modal.tamia');
                this.ui.form.trigger('loading-stop.tamia');
                this.trigger('error:promo', this.message);
            });
        },
        submit: function () {
            var data = Backbone.Syphon.serialize(this);
            this.model.forgot(data);
            return false;
        }
    });
    return UserForgotFormView;
});
define('user/user.reset.form.view', [
    'layouts/form.view',
    'user/user.login.form.view'
], function (FormView, UserLoginFormView) {
    'use strict';
    var UserResetFormView = UserLoginFormView.extend({
        el: $('.js-reset-form'),
        initialize: function () {
            FormView.prototype.initialize.apply(this, arguments);
            this.message = this.$el.data('message');
            this.listenTo(this.model, 'validated:valid', function () {
                this.ui.form.trigger('loading-start.tamia');
                this.ui.inputs.removeState('invalid');
            }).listenTo(this.model, 'validated:invalid', this.errback).listenTo(this.model, 'resetpassword:fail', this.errback).listenTo(this.model, 'resetpassword:success', function () {
                this.$el.trigger('close.modal.tamia');
                this.ui.form.trigger('loading-stop.tamia');
                this.trigger('error:promo', this.message);
            }.bind(this));
        },
        submit: function () {
            var data = Backbone.Syphon.serialize(this);
            this.model.resetPassword(data, this.ui.form.attr('action'));
            return false;
        }
    });
    return UserResetFormView;
});
define('looker/button.view', ['stat'], function (stat) {
    'use strict';
    var LookerButtonView = Marionette.ItemView.extend({
        el: $('.js-looker-button'),
        events: { click: 'request' },
        urlLooker: '/api/user/start',
        initialize: function () {
            this.bindUIElements();
        },
        request: function () {
            this.$el.trigger('loading-start.tamia');
            $.post(this.urlLooker, {}).fail(function () {
                this.$el.trigger('loading-stop.tamia');
                throw 'Can\'t create looker';
            }.bind(this)).done(function () {
                stat.push('create_looker');
                location.reload();
            });
            return false;
        }
    });
    return LookerButtonView;
});
define('iphone/button.view', ['stat'], function (stat) {
    'use strict';
    var iPhoneButtonView = Marionette.ItemView.extend({
        el: $('.js-iphone-button'),
        events: { click: 'request' },
        initialize: function () {
            this.bindUIElements();
        },
        request: function () {
            stat.push('iphone_version_button');
        }
    });
    return iPhoneButtonView;
});
define('app.promo', [
    'stat',
    'settings',
    'user/user.model',
    'user/user.login.form.view',
    'user/user.forgot.form.view',
    'user/user.reset.form.view',
    'looker/button.view',
    'iphone/button.view'
], function (stat, settings, User, UserLoginFormView, UserForgotFormView, UserResetFormView, LookerButtonView, IPhoneButtonView) {
    'use strict';
    var AppPromo = Marionette.Application.extend({
        initialize: function () {
            if (!settings.CSRF) {
                return;
            }
            var loginForm = new UserLoginFormView({ model: new User() }), forgotForm = new UserForgotFormView({ model: new User() }), resetForm = new UserResetFormView({ model: new User() });
            new IPhoneButtonView();
            new LookerButtonView();
            stat.push('appinit_landing');
            loginForm.on('error:promo', function (message) {
                app.vent.trigger('error:promo', { message: message });
            });
            forgotForm.on('error:promo', function (message) {
                app.vent.trigger('error:promo', { message: message });
            });
            resetForm.on('error:promo', function (message) {
                app.vent.trigger('error:promo', { message: message });
            });
        }
    });
    var app = new AppPromo({
        container: 'body',
        regions: {}
    });
    return app;
});
define('notifications/notification.model', [], function () {
    'use strict';
    var Notification = Backbone.Model.extend({
        defaults: {
            type: 'blocking',
            repeat: null,
            context: null,
            closeButton: true,
            message: '',
            count: 0
        }
    });
    return Notification;
});
define('notifications/notification.view', [], function () {
    'use strict';
    var NotificationView = Marionette.ItemView.extend({
        close: function () {
            this.$el.trigger('disappear.tamia');
        },
        open: function () {
            this.$el.trigger('appear.tamia');
        }
    });
    return NotificationView;
});
define('notifications/error.notification.view', [
    'utils',
    'notifications/notification.view'
], function (utils, NotificationView) {
    'use strict';
    var ErrorNotificationView = NotificationView.extend({
        template: '#tpl-notif-error',
        tagName: 'div',
        className: 'notification-bar notification-bar_warning is-hidden',
        events: { 'click .js-notif-link': 'tryRepeat' },
        initialize: function () {
            var $template = $(this.template);
            this.queryInflect = $template.data('queryInflect').split(',');
            this.sentInflect = $template.data('sentInflect').split(',');
        },
        onBeforeRender: function () {
            this.model.set({
                queryForm: utils.inflect(this.count, this.queryInflect),
                sentForm: utils.inflect(this.count, this.sentInflect)
            });
        },
        tryRepeat: function () {
            var model = this.model, repeat = model.get('repeat'), context = model.get('context');
            if (repeat && context) {
                repeat.call(context);
                this.remove();
            }
            return false;
        }
    });
    return ErrorNotificationView;
});
define('notifications/notification.layout', [], function () {
    'use strict';
    var NotificationLayout = Marionette.LayoutView.extend({
        template: false,
        el: '.js-notifications',
        add: function (view) {
            this.$el.append(view.render().el);
            view.open();
            return this;
        }
    });
    return NotificationLayout;
});
define('notifications/notification.promo.module', [
    'app.promo',
    'settings',
    'notifications/notification.model',
    'notifications/error.notification.view',
    'notifications/notification.layout'
], function (app, settings, Notification, ErrorNotificationView, NotificationLayout) {
    'use strict';
    app.addInitializer(function () {
        var layout = new NotificationLayout(), errorNotif;
        app.vent.on('error:promo', function (data) {
            if (errorNotif instanceof ErrorNotificationView) {
                errorNotif.remove();
            }
            var model = new Notification(data);
            errorNotif = new ErrorNotificationView({ model: model });
            layout.add(errorNotif);
        });
    });
});
require([
    'app.promo',
    'notifications/notification.promo.module'
], function (app) {
    'use strict';
    app.start({ doYouSpeekEnglish: 'No, I don\'t!' });
    window.app = app;
});
define('init.promo', [
    'app.promo',
    'notifications/notification.promo.module'
], function () {
    return;
});